var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{style:({
        width: '100vw',
        height: '100vh',
        'background-image': 'url(' + _vm.picUrl + ')',
        'background-repeat': 'no-repeat',
        'background-size': '100% 100%',
        '-moz-background-size': '100% 100%',
    })},[_c('div',{staticStyle:{"overflow":"scroll","height":"100%"}},[_c('div',{staticStyle:{"padding":"20px 12px 12px 12px"}},[_c('div',{staticClass:"title"},[_c('div',{style:({
                        'background-image': 'url(' + _vm.title + ')',
                        'background-size': '100% 100%',
                        'background-repeat': 'no-repeat',
                        'padding': '60px 36px 30px 36px',
                    }),domProps:{"innerHTML":_vm._s(_vm.single.title)}})]),_vm._l((_vm.single.detail),function(detail,j){return _c('div',{key:j},[(detail.type == 'txt')?_c('div',{staticStyle:{"border-radius":"8px","padding":"8px","margin-bottom":"4px","background-color":"rgba(255, 255, 255, 0.8)"}},[_c('div',{staticStyle:{"line-height":"26px","font-weight":"500","color":"#323233"},domProps:{"innerHTML":_vm._s(detail.value)}})]):(detail.type == 'img')?_c('van-image',{staticStyle:{"width":"100%","margin-bottom":"4px"},attrs:{"lazy-load":"","src":require('./../../assets/sourceRoad/detail/' + detail.value),"fit":"contain"},on:{"click":function($event){_vm.getImg(require('./../../assets/sourceRoad/detail/' + detail.value))}}}):(detail.type == 'tip')?_c('div',{staticStyle:{"padding-top":"20px","text-align":"start"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center"}},[_c('div',{staticClass:"tip",style:({
                                'background-image': 'url(' + _vm.tip + ')',
                                'background-size': '100% 100%',
                                'background-repeat': 'no-repeat',
                                'padding': '10px 46px 20px 46px'
                            })},[_vm._v(_vm._s(detail.value))])]),_c('div',{staticStyle:{"border-radius":"8px","padding":"8px","margin-top":"12px","background-color":"rgba(255, 255, 255, 0.8)"}},_vm._l((detail.children),function(item,i){return _c('div',{key:'item' + i},[(item.type == 'txt')?_c('div',[_c('div',{staticStyle:{"line-height":"26px","font-weight":"500","color":"#323233"},domProps:{"innerHTML":_vm._s(item.value)}})]):(item.type == 'img')?_c('van-image',{staticStyle:{"width":"100%","margin-bottom":"4px","border-radius":"8px","overflow":"hidden"},attrs:{"lazy-load":"","src":require('./../../assets/sourceRoad/detail/' + item.value),"fit":"contain"},on:{"click":function($event){_vm.getImg(require('./../../assets/sourceRoad/detail/' + item.value))}}}):(item.type == 'imgGroup')?_c('van-swipe',{staticClass:"my-swipe",staticStyle:{"height":"50vw","border-radius":"8px","overflow":"hidden"},attrs:{"autoplay":3000,"indicator-color":"#F9B22B"}},_vm._l((item.value),function(img,l){return _c('van-swipe-item',{key:'shopImg' + l},[_c('van-image',{attrs:{"lazy-load":"","width":"100%","height":"100%","src":require('./../../assets/sourceRoad/detail/' + img),"fit":"cover","position":"center"}})],1)}),1):_vm._e()],1)}),0)]):_vm._e()],1)})],2)])])])}
var staticRenderFns = []

export { render, staticRenderFns }